/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'node_modules/bootstrap/scss/bootstrap';

@import 'assets/css/font-awesome/css/all.min.css';

/* for example, let's change the mouse hover color */
$cell-odd-background-color: lightyellow;
$row-mouse-hover-color: #dfdfdf;
$row-mouse-hover: pointer;
/*cursor: pointer;*/


/* make sure to add the @import the SlickGrid Bootstrap Theme AFTER the variables changes */
@import '../node_modules/angular-slickgrid/styles/sass/slickgrid-theme-bootstrap.scss';



/* incompatibilidad con slickgrid
html,
body {
    height: 100%;
}
*/

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #050506;
}

.mat-toolbar.mat-primary {
    /* header */
    color: #fff;
    /*background: #171717;*/
    background: #fafafab0;
}

.mat-drawer.mat-drawer-side
/* aside */

{
    background: #191919;
    color: #fff;
}

.custom-modalbox>mat-dialog-container {
    padding: 0px;
}

.mat-raised-button.mat-primary {
    background-color: #df0b08;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #df0b08;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: #df0b08;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(223, 11, 8, 0.54);
}

.mat-raised-button.mat-warn {
    background-color: #4a4a4a;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #df0b08;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #df0b08;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
    border-color: #df0b08;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
    background-color: #df0b08;
    /* background-color: #e6bb00; */
}

.mat-mini-fab.mat-warn {
    background-color: #4a4a4a;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #df0b08;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.bold {
    font-weight: bold;
  }
  
  .italic {
    font-style: italic;
  }
  
  .grey {
    color: grey;
  }
  
  .orange {
    color: orange;
  }
  
  .red {
    color: red;
  }
  
  .green {
    color: green;
  }

  .yellow {
    color: rgb(255, 235, 52);
  }
  
  .pointer {
    cursor: pointer;
  }
  
  .disabled {
    color: #ccc;
  }
  
  .fake-hyperlink {
    cursor: pointer;
    color: #08c;
  }
  
  .row.locale {
    margin-top: 5px;
  }
  
  span.cell-menu {
    margin-left: 15px;
  }

  .reallyHidden { display: none !important;}

